<template>
  <a-card :bordered="false">
    <div class="table-operator" align="right">
      <a-button v-action:add icon="plus" type="primary" @click="handleAdd()">新建</a-button>
      <a-dropdown v-if="selectedRowKeys.length > 0" v-action:del>
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="moreDel">
            <a-icon type="delete"/>
            删除
          </a-menu-item>
        </a-menu>
        <a-button>
          批量操作
          <a-icon type="down"/>
        </a-button>
      </a-dropdown>
    </div>
    <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择&nbsp;<a style="font-weight: 600">{{
        selectedRowKeys.length }}</a>项&nbsp;&nbsp;
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div>
    <a-table
      ref="table"
      :columns="columns"
      :data-source="nodes"
      :scroll="{ x: 800 }"
      :row-selection="rowSelection"
      bordered
      size="default"
    >
      <span slot="action" slot-scope="text, record">
        <a v-if="record.type!=3" v-action:add @click="handleChildNew(record)">新增</a>
        <a-divider v-action:edit type="vertical"/>
        <a v-action:edit @click="handleEdit(record)">编辑</a>
        <a-divider v-action:state type="vertical"/>
        <a v-if="record.status==1" v-action:state @click="handleStatus(record)">禁用</a>
        <a v-if="record.status==2" v-action:state @click="handleStatus(record)">启用</a>
        <a-divider v-action:del type="vertical"/>
        <a v-action:del @click="handleDel(record)">删除</a>
      </span>
    </a-table>
    <a-modal
      v-model="visible"
      :confirmLoading="loading"
      :title="title"
      :width="1200"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <formCreate
        ref="createModal"
        :model="mdl"
      />
    </a-modal>
  </a-card>
</template>

<script>
  import { STable } from '@/components'
  import { allList, create, del, update } from '@/api/menu'
  import { formatterTime } from '@/utils/util'
  import formCreate from './menuform'
  import pick from 'lodash.pick'
  import { moreDel } from '@/api/admin'

  export default {
    components: {
      STable,
      formCreate
    },
    data () {
      return {
        check: {
          enable: true
        },
        selectedRowKeys: [],
        selectedRows: [],
        visible: false,
        confirmLoading: false,
        loading: false,
        title: '',
        mdl: null,
        // 查询参数
        queryParam: {
          parentId: 0
        },
        nodes: [],
        orgTree: [],
        // 表头
        columns: [
          {
            title: '菜单名称',
            dataIndex: 'title'
          },
          {
            title: '模块名称',
            dataIndex: 'moduleName'
          },
          {
            title: '系统菜单',
            dataIndex: 'isSystem',
            customRender: (val) => {
              return val === 1 ? '是' : '否'
            }
          },
          {
            title: '状态',
            dataIndex: 'status',
            customRender: (val) => {
              return val === 1 ? '启用' : '禁用'
            }
          },
          {
            title: '创建时间',
            align: 'center',
            dataIndex: 'createTime',
            customRender: (val) => {
              return formatterTime(val)
            }
          }, {
            title: '操作',
            width: '200px',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' }
          }
        ],
        pagination: {
          onChange: (page, pageSize) => {
            console.log(23423423)
            this.selectedRowKeys = []
            this.selectedRows = []
          },
          onShowSizeChange: (page, pageSize) => {
            console.log(23423423)
            this.selectedRowKeys = []
            this.selectedRows = []
          }
        }
      }
    },
    mounted () {
      this.getAllList()
    },

    computed: {
      rowSelection () {
        return {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      }
    },
    methods: {
      getAllList () {
        return allList()
          .then(res => {
            this.nodes = res.result
          })
      },
      handleAdd () {
        this.title = '新增'
        this.mdl = null
        this.visible = true
      },
      handleEdit (record) {
        console.log(record)
        this.title = '编辑'
        this.visible = true
        this.$nextTick(function () {
          const form = this.$refs.createModal.form
          const fields = ['parentId', 'title', 'status', 'id', 'name', 'itemkey', 'path', 'hiddenHeaderContent',
            'keepAlive', 'target',
            'hideChildren', 'isShow', 'icon', 'listorder', 'redirect', 'component', 'moduleId'
          ]
          const datafleld = record
          if (record.parentId === 0) {
            datafleld.parentId = undefined
          }
          form.setFieldsValue(pick(datafleld, fields))
        })
      },

      handleChildNew (record) {
        this.title = '新增子菜单'
        this.visible = true
        this.$nextTick(function () {
          const form = this.$refs.createModal.form
          const fields = ['parentId', 'title', 'status', 'id', 'name', 'itemkey', 'path', 'hiddenHeaderContent',
            'keepAlive', 'target',
            'hideChildren', 'isShow', 'icon', 'listorder', 'redirect', 'component', 'moduleId']
          const datafleld = { name: '', status: 1, id: 0, parentId: record.id }
          form.setFieldsValue(pick(datafleld, fields))
        })
      },
      handleOk () {
        const self = this
        const form = this.$refs.createModal.form
        form.validateFields((errors, values) => {
          if (!errors) {
            if (values.id > 0) {
              // 修改 e.g.
              update(values)
                .then(res => {
                  if (res.code === 1) {
                    this.visible = false
                    this.confirmLoading = false
                    // 重置表单数据
                    form.resetFields()
                    // 刷新表格
                    self.getAllList()
                    self.$message.info('修改成功')
                  } else {
                    self.$message.error(res.message)
                  }
                })
            } else {
              // 新增
              create(values)
                .then(res => {
                  if (res.code === 1) {
                    this.visible = false
                    this.confirmLoading = false
                    // 重置表单数据
                    form.resetFields()
                    // 刷新表格
                    self.getAllList()

                    self.$message.info('新增成功')
                  } else {
                    self.$message.error(res.message)
                  }
                })
            }
          } else {
            this.confirmLoading = false
          }
        })
      },
      handleCancel () {
        this.visible = false
        const form = this.$refs.createModal.form
        form.resetFields() // 清理表单数据（可不做）
      },
      handleDel (record) {
        const self = this
        this.$confirm({
          title: '确定要删除吗？',
          content: '删除后将不可恢复，请谨慎操作！',
          okText: '确认',
          cancelText: '取消',
          onOk () {
            del(record)
              .then(res => {
                if (res.code === 1) {
                 self.getAllList()
                  self.$message.info(res.message)
                } else {
                  self.$message.error(res.message)
                 self.getAllList()
                }
              })
          }

        })
      },
      handleStatus (record) {
        const self = this
        this.$nextTick(function () {
          if (record.status === 1) {
            record.status = 2
          } else {
            record.status = 1
          }
          update(record)
            .then(res => {
              // 刷新表格
              self.getAllList()
              self.$message.info('修改成功')
            })
        })
      },
      onSelectChange (selectedRowKeys, selectedRows) {
        this.selectedRowKeys = selectedRowKeys
        this.selectedRows = selectedRows
      },
      onClearSelected () {
        this.selectedRowKeys = []
        this.selectedRows = []
      },
      moreDel () {
        const self = this
        const arr = []
        if (this.selectedRows.length > 0) {
          for (let i = 0; i < this.selectedRows.length; i++) {
            arr.push(this.selectedRows[i].id)
          }
          moreDel({ 'menuIds': arr.join(',') })
            .then(res => {
              if (res.code === 1) {
                self.getAllList()
                this.selectedRowKeys = []
                this.selectedRows = []
                self.$message.info(res.message)
              } else {
                self.$message.error(res.message)
              }
            })
        }
      }
    }
  }
</script>
